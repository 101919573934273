import { Box } from '@mui/material';
import ReactPlayer from 'react-player/youtube';
import React from 'react';
import Image from '#/components/shared/ui/Image';
import useResponsive from '#/hooks/useResponsive';

type VideoTutorialProps = {
  youtubeLink: string;
};

export default function VideoTutorial({ youtubeLink }: VideoTutorialProps) {
  const isMobile = useResponsive('down', 'md');

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: 1,
      }}
    >
      <ReactPlayer
        url={youtubeLink}
        playing
        light={
          <Image
            className="react-player__preview"
            src="/assets/images/youtubeThumbnail.webp"
            alt="youtube_introduction"
            sx={{
              borderRadius: 2,
              padding: 0,
            }}
          />
        }
        width={isMobile ? '100%' : 670}
        height={isMobile ? 200 : 400}
      />
    </Box>
  );
}
